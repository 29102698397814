import React        from "react"
import {NewsLayout} from "sections/News/"
import useNewsData  from "shared/Hooks/News/useNewsData"
import {List}       from "src/sections/News/NewsLayout/List";
import {TextBlock}  from "src/sections/News/NewsLayout/TextBlock";
import {Video}      from "src/sections/News/NewsLayout/Video/video";
import {NEWS_11}    from "types/News"

const Article = () => {
  const data = useNewsData(NEWS_11);

  return (
    <NewsLayout data={data}>
      <Video data={data.videos.first}/>
      <List data={data.lists.first}/>
      <TextBlock data={data.texts.first}/>
    </NewsLayout>
  )
}

export default Article;
